import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Device } from '@twilio/voice-sdk';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Box, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import HistoryIcon from '@mui/icons-material/History';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import './CallWidget.css';

const CallWidget = ({ phoneNumber }) => {
  const [recordings, setRecordings] = useState([]);
  const [calling, setCalling] = useState(false);
  const [loadingRecordings, setLoadingRecordings] = useState(false);
  const [device, setDevice] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [callDetails, setCallDetails] = useState(null);
  const [playing, setPlaying] = useState(null);

  const setupDevice = useCallback(async () => {
    try {
      const identity = phoneNumber.replace(/^\+/, '');
      const response = await axios.get(`https://imagesconvert.com/api/calling/token?identity=${identity}`);
      const token = response.data.token;
      const newDevice = new Device(token, {
        logLevel: 1,
        codecPreferences: ['opus', 'pcmu']
      });
      await newDevice.register();
      setDevice(newDevice);
      console.log('设备设置完成', newDevice);
      return newDevice;
    } catch (error) {
      console.error('设置Twilio设备时出错:', error);
    }
  }, [phoneNumber]);

  const destroyDevice = useCallback(() => {
    if (device) {
      device.destroy();
      setDevice(null);
    }
  }, [device]);

  const handleCall = async () => {
    console.log('开始呼叫');
    let currentDevice = device;
    if (!currentDevice && !isConnecting) {
      console.log('设置设备');
      currentDevice = await setupDevice();
    }
    if (currentDevice && !isConnecting) {
      try {
        setIsConnecting(true);
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setCalling(true);
        console.log('连接呼叫');
        const call = await currentDevice.connect({ params: { To: phoneNumber } });

        call.on('accept', (call) => {
          console.log('呼叫已接通:', call.parameters);
        });

        call.on('disconnect', () => {
          setCalling(false);
          setIsConnecting(false);
          destroyDevice();
          console.log('呼叫已结束');
        });

        call.on('error', (error) => {
          setCalling(false);
          setIsConnecting(false);
          destroyDevice();
          console.error('呼叫过程中出错:', error);
        });
      } catch (error) {
        setCalling(false);
        setIsConnecting(false);
        destroyDevice();
        console.error('访问麦克风或连接时出错:', error);
      }
    }
  };

  const handleLoadRecordings = async () => {
    if (!phoneNumber) {
      console.log('无可用的电话号码');
      return;
    }
    setLoadingRecordings(true);
    try {
      const response = await axios.get(`https://imagesconvert.com/api/calling/recordings?callSid=${phoneNumber}`);
      setRecordings(response.data);
      console.log('获取到的录音:', response.data);
      setOpen(true);
    } catch (error) {
      console.error('获取录音时出错:', error);
    } finally {
      setLoadingRecordings(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPlaying(null);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleCallClick = () => {
    setConfirmOpen(true);
  };

  const handleConfirmCall = () => {
    setConfirmOpen(false);
    console.log('确认呼叫');
    handleCall();
  };

  const handleDetailsClick = (details) => {
    setCallDetails(details);
  };

  const handlePlay = (index) => {
    setPlaying(playing === index ? null : index);
  };

  return (
    <Box className="call-widget" p={2} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" gutterBottom sx={{ color: 'black' }}>
        {phoneNumber}
      </Typography>
      <Box mt={2} display="flex" justifyContent="space-between" width="100%">
        <IconButton onClick={handleCallClick} disabled={calling || isConnecting} color="primary">
          <CallIcon />
        </IconButton>
        <IconButton onClick={handleLoadRecordings} disabled={loadingRecordings} color="secondary">
          {loadingRecordings ? <CircularProgress size={24} /> : <HistoryIcon />}
        </IconButton>
      </Box>
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>确认拨打电话</DialogTitle>
        <DialogContent>你确定要拨打这个电话吗？</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">取消</Button>
          <Button onClick={handleConfirmCall} color="primary">确认</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            height: 'auto',
            maxHeight: '90vh',
            width: '95vw',
            maxWidth: 'none'
          }
        }}
      >
        <DialogTitle>录音列表</DialogTitle>
        <DialogContent>
          {loadingRecordings ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 200 }}>通话 SID</TableCell>
                    <TableCell style={{ minWidth: 120 }}>来电号码</TableCell>
                    <TableCell style={{ minWidth: 120 }}>接听号码</TableCell>
                    <TableCell style={{ minWidth: 100 }}>通话时长 (秒)</TableCell>
                    <TableCell style={{ minWidth: 100 }}>录音</TableCell>
                    <TableCell style={{ minWidth: 200 }}>通话文本</TableCell>
                    <TableCell style={{ minWidth: 200 }}>摘要</TableCell>
                    <TableCell style={{ minWidth: 80 }}>详情</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recordings.map((recording, index) => (
                    <TableRow key={recording.callSid} hover>
                      <TableCell>{recording.callSid}</TableCell>
                      <TableCell>{recording.from_phone_number}</TableCell>
                      <TableCell>{recording.to_phone_number}</TableCell>
                      <TableCell>{recording.duration}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handlePlay(index)}>
                          {playing === index ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                        {playing === index && (
                          <audio
                            src={recording.s3_url}
                            autoPlay
                            onEnded={() => setPlaying(null)}
                            controls
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography noWrap style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {recording.callingtxt}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {recording.summary}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="查看详情">
                          <IconButton onClick={() => handleDetailsClick(recording)}>
                            <HistoryIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>
      {callDetails && (
        <Dialog open={Boolean(callDetails)} onClose={() => setCallDetails(null)} fullWidth maxWidth="md">
          <DialogTitle>通话详情</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1"><strong>通话 SID:</strong> {callDetails.callSid}</Typography>
            <Typography variant="body1"><strong>来电号码:</strong> {callDetails.from_phone_number}</Typography>
            <Typography variant="body1"><strong>接听号码:</strong> {callDetails.to_phone_number}</Typography>
            <Typography variant="body1"><strong>通话时长:</strong> {callDetails.duration} 秒</Typography>
            <Typography variant="body1"><strong>通话文本:</strong> {callDetails.callingtxt}</Typography>
            <Typography variant="body1"><strong>摘要:</strong> {callDetails.summary}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCallDetails(null)} color="primary">
              关闭
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default CallWidget;